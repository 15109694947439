@font-face {
    font-family: 'mg-icons';
        src: url('../fonts/mg-icons/mg-icons.ttf?mgje0v') format('truetype'),
             url('../fonts/mg-icons/mg-icons.woff?mgje0v') format('woff');
    font-weight: normal;
    font-style: normal;
}

.icon {
    font-family: 'mg-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-filter {
    content: @icon-filter;
}

.icon-facebook-1 {
    content: @icon-facebook-1;
}

.icon-telegram {
    content: @icon-telegram;
}

.icon-sale {
    content: @icon-sale;
}

.icon-arrow-right {
    content: @icon-arrow-right;
}

.icon-arrow-left {
    content: @icon-arrow-left;
}

.icon-cross {
    content: @icon-cross;
}

.icon-1 {
    content: @icon-1;
}

.icon-air-pump {
    content: @icon-air-pump;
}

.icon-arm {
    content: @icon-arm;
}

.icon-cart {
    content: @icon-cart;
}

.icon-chain {
    content: @icon-chain;
}

.icon-dislike {
    content: @icon-dislike;
}

.icon-facebook {
    content: @icon-facebook;
}

.icon-fist {
    content: @icon-fist;
}

.icon-heart {
    content: @icon-heart;
}

.icon-instagram {
    content: @icon-instagram;
}

.icon-like {
    content: @icon-like;
}

.icon-login {
    content: @icon-login;
}

.icon-mail {
    content: @icon-mail;
}

.icon-mountain {
    content: @icon-mountain;
}

.icon-recycle {
    content: @icon-recycle;
}

.icon-reply {
    content: @icon-reply;
}

.icon-rss {
    content: @icon-rss;
}

.icon-search {
    content: @icon-search;
}

.icon-star {
    content: @icon-star;
}

.icon-tr {
    content: @icon-tr;
}

.icon-trash {
    content: @icon-trash;
}

.icon-twitter {
    content: @icon-twitter;
}

.icon-youtube {
    content: @icon-youtube;
}
.icon-telegram {
  content: @icon-telegram;
}

.icon-checkmark {
    content: @icon-checkmark;
}