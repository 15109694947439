//
//  Resolutions
//  ---------------------------------------------

//@screen-xxs             : 375px;
//@screen-xs              : 480px;
//@screen-m               : 768px;
//@screen-l               : 1024px;
//@screen-xl              : 1280px;
//@screen-xxl             : 1440px;

//
//  Mixins
//  ---------------------------------------------

.media-max(@width, @rules) {
    @media screen and (max-width: (@width - 1px)) {
        @rules();
    }
}

.media-min(@width, @rules) {
    @media screen and (min-width: @width) {
        @rules();
    }
}