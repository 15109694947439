@import 'core/_variables.less';
@import 'core/_responsive.less';
@import 'core/_icon-fonts.less';

@import 'source/_mixins.less';

.cms-index-index {
    overflow-x: hidden;

    .messages {
        padding-top: 20px;
    }
}

.hp-top-banner {
    margin: 8px -5px 24px;

    & + .std {
        display: none;
    }

    .media-min(@screen-m, {
        margin-bottom: 30px;
    });

    .media-min(@screen-l, {
        margin-top: 42px;
    });

    .slick-prev {
        left: 5px;
    }

    .slick-next {
        right: 5px;
    }

    &.slick-initialized {
        .slider {
            &:not(:first-child) {
                display: block;
            }
        }
    }

    .slider {
        padding: 0 5px;

        &:not(:first-child) {
            display: none;
        }

        .slick-initialized & {
            &:not(:first-child) {
                display: block;
            }
        }

        &.slider-full {
            .content-wrap {
                .info-wrap {
                    position: absolute;
                    padding: 0;
                    left: 50%;
                    bottom: 15px;
                    width: 100%;
                    background: transparent;
                    transform: translateX(-50%);
                    z-index: 1;

                    .media-min(@screen-xs, {
                        bottom: 20px;
                    });

                    .media-min(@screen-m, {
                        bottom: 45px;
                    });

                    .text-wrap {
                        width: 100%;
                        max-width: none;

                        &:before {
                            display: none;
                        }
                    }

                    .main-btn {
                        display: block;
                        width: 180px;
                        margin: auto;

                        .media-min(@screen-xl, {
                            width: 15.9%;
                        });
                    }
                }

                .image-wrap {
                    width: 100%;
                    padding-top: 30.6%;
                    margin: 0;

                    .media-max(@screen-m, {
                        padding-top: 160%;
                    });
                }
            }
        }

        .content-wrap {
            position: relative;
            overflow: hidden;
            border-radius: 8px;

            .media-min(@screen-m, {
                display: flex;
            });

            .info-wrap {
                display: flex;
                padding: 41px 32px 25px;
                background: #272727;
                color: @color-white;

                .media-min(@screen-m, {
                    width: 45%;
                });

                .media-min(@screen-l, {
                    position: relative;
                    padding: 51px 32px;
                });

                .text-wrap {
                    max-width: 290px;
                    margin: auto;

                    .media-min(@screen-l, {
                        &::before {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            right: -50px;
                            width: 100px;
                            height: 180px;
                            background: #272727;
                            z-index: 1;
                        }
                    });
                }

                .border {
                    .media-min(@screen-l, {
                        position: absolute;
                        height: 999px;
                        width: 33px;
                        background: #E0D118;
                        z-index: 2;
                        top: -50px;
                        left: 36px;
                        transform: rotate(-19deg);

                        &::before,
                        &::after {
                            content: '';
                            position: absolute;
                            height: 100%;
                            width: 33px;
                        }

                        &::before {
                            left: calc(~'-100% + 1px');
                            background: @color-yellow;
                        }

                        &::after {
                            right: calc(~'-100% + 1px');
                            background: #ADA216;
                        }

                        &:not(:first-child) {
                            right: -120px;
                            left: auto;
                        }
                    });
                }

                .block-title {
                    padding-bottom: 5px;
                    font-size: 46px;
                    font-weight: @font-weight-bold;

                    .media-max(@screen-xxs, {
                        font-size: 36px;
                    });
                }

                li {
                    position: relative;
                    padding-bottom: 14px;
                    padding-left: 30px;
                    font-weight: @font-weight-light;

                    .media-min(@screen-xxs, {
                        font-size: @font-xs;
                    });

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 7px;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: @color-yellow;
                    }
                }

                .main-btn {
                    width: 100%;
                    max-width: 240px;
                    border-radius: 4px;
                    font-size: @font-xxl;
                    z-index: 1;

                    .media-max(@screen-m, {
                        position: absolute;
                        bottom: 20px;
                        left: 50%;
                        transform: translateX(-50%);
                    });
                }
            }

            .image-wrap {
                position: relative;
                padding-top: 30.6%;

                .media-max(@screen-m, {
                    padding-top: 100%;
                    margin-top: -26px;
                });

                .media-min(@screen-m, {
                    width: 55%;
                });

                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.hp-categories-block {
    .media-max(@screen-xxl, {
        display: none;
    });

    > .title {
        margin-bottom: 25px;
        text-align: center;

        h1 {
            position: relative;
            display: inline;
            line-height: 1;
            font-size: 48px;
            z-index: 1;

            &::before {
                content: '';
                position: absolute;
                left: -40px;
                right: -40px;
                bottom: 20px;
                height: 19px;
                background: @color-yellow;
                z-index: -1;
            }
        }
    }

    .nav-dropdown-wrap {
        position: relative;
        margin-bottom: 30px;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);

        .nav-toggle {
            display: none;
        }

        ul {
            margin: 0;
        }

        li {
            position: static;
            padding: 0;
            margin: 0;
            line-height: unset;

            &::before {
                display: none;
            }
        }
    }
}

.hp-awards-block {
    margin-bottom: 18px;

    .media-min(@screen-m, {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 44px;
    });

    .banner {
        position: relative;
        margin-bottom: 8px;
        border-radius: 8px;
        overflow: hidden;
        font-weight: @font-weight-bold;

        .media-min(@screen-m, {
            margin: 0;
        });

        &.big-banner {
            .media-min(@screen-m, {
                width: 100%;
                margin-bottom: 20px;
            });

            .text-wrap {
                p {
                    font-size: 36px;
                    line-height: 1.3;
                }

                .media-min(@screen-l, {
                    right: auto;
                    max-width: 640px;

                    p {
                        font-size: 64px;
                    }
                });

                .media-min(@screen-xl, {
                    top: 50%;
                    left: 94px;
                    transform: translateY(-50%);

                    p {
                        font-size: 72px;
                    }
                });

                .actions-wrap {
                    a {
                        margin-top: 10px;
                        border-radius: 4px;
                        font-size: 18px;

                        .media-min(@screen-l, {
                            margin-top: 20px;
                            font-size: 24px;
                        });
                    }
                }
            }
        }

        &.small-banner {
            .media-min(@screen-m, {
                width: calc(~'33.333% - 10px');
            });

            .text-wrap {
                font-size: 26px;
                line-height: 1.28;

                .media-max(@screen-xxs, {
                    font-size: @font-xl;
                });

                .media-min(@screen-m, {
                    top: 20px;
                    left: 20px;
                    right: 10px;
                    font-size: @font-m;
                });

                .media-min(@screen-xl, {
                    top: 28px;
                    left: 25px;
                    max-width: 400px;
                    font-size: 30px;
                });
            }
        }
        
        &.banner-3 {
            .text-wrap {
                top: auto;
                bottom: 30px;
                left: 50%;
                right: auto;
                width: 90%;
                transform: translateX(-50%);
                text-align: center;

                .media-min(@screen-m, {
                    bottom: 20px;
                });

                .media-min(@screen-xl, {
                    bottom: 40px;
                });
            }
        }

        .image-wrap {
            img {
                width: 100%;
            }
        }

        .text-wrap {
            position: absolute;
            top: 26px;
            left: 18px;
            right: 18px;
            color: @color-white;
        }
        
        .yellow {
            color: @color-yellow;
        }
        
        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.hp-links-block {
    .media-min(@screen-m, {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -9px;
    });

    .media-min(@screen-xl, {
        margin: 0 -12px;
    });

    .block {
        margin-bottom: 17px;

        .media-min(@screen-m, {
            width: 50%;
            padding: 0 9px;
        });

        .media-min(@screen-l, {
            width: 33.333%;
        });

        .media-min(@screen-xl, {
            width: 20%;
            padding: 0 12px;
            margin-bottom: 39px;
        });

        > a {
            position: relative;
            display: block;
            height: 100%;
            padding: 15px 60px 10px 20px;
            min-height: 98px;
            color: @color-black;
            border: 3px solid @color-black;
            border-radius: 8px;
            text-decoration: none;
            transition: border-color .3s ease-in-out;

            &:hover {
                border-color: @color-yellow;
            }
        }

        .text-wrap {
            .title {
                margin-bottom: 5px;
                text-transform: uppercase;
                font-weight: @font-weight-bold;
            }

            .subtitle {
                font-size: @font-xxs;
            }
        }

        .arrow {
            position: absolute;
            top: 50%;
            right: 30px;
            width: 30px;
            height: 30px;
            transform: translateY(-50%);
            background: @color-yellow;
            border-radius: 8px;

            &::before {
                .icon;
                .icon-arrow-right;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: @color-black;
                font-size: 15px;
            }
        }
    }
}

.hp-welcome-block {
    padding: 77px 25px 43px;
    margin-bottom: 32px;
    background: #f8f8f8;
    border-radius: 8px;

    .media-min(@screen-m, {
        position: relative;
        min-height: 150px;
        padding: 56px 38px 56px 50%;
    });

    .media-min(@screen-l, {
        padding-left: 42%;
    });

    .logo {
        width: 248px;
        height: 93px;
        margin: 0 auto 62px;
        background: url('../images/logo-black.svg') center no-repeat;
        background-size: cover;

        .media-min(@screen-m, {
            position: absolute;
            top: 50%;
            left: 25%;
            transform: translate(-50%, -50%);
        });

        .media-min(@screen-l, {
            left: 21%;
        });
    }

    .content-wrap {
        font-size: @font-xs;
        line-height: 1.75;
    }
}

.hp-supplier-block {
    padding-bottom: 35px;
    text-align: center;

    .block-title {
        margin-bottom: 16px;

        .media-min(@screen-m, {
            position: relative;
            margin-bottom: 30px;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                background: @color-border;
                transform: translateY(-50%);
            }
        });

        .title {
            font-size: @font-m;
            font-weight: @font-weight-bold;
            text-transform: uppercase;

            .media-min(@screen-m, {
                display: inline-block;
                position: relative;
                font-size: @font-xxl;
                padding: 0 56px;
                background: @color-white;
            });
        }
    }

    .text-wrap {
        margin-bottom: 35px;
        font-size: @font-xs;
        line-height: 1.75;

        .media-min(@screen-m, {
            max-width: 830px;
            margin: 0 auto 40px;
        });

        p {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    .brands-block {
        display: flex;
        flex-wrap: wrap;
        max-width: 400px;
        margin: 0 auto;

        .media-min(@screen-l, {
            max-width: 1175px;
        });

        .brand {
            width: 50%;
            padding: 0 6px;
            margin-bottom: 32px;

            &::before {
                display: none;
            }

            .media-min(@screen-m, {
                margin-bottom: 40px;
            });

            .media-min(@screen-l, {
                width: 25%;
                display: flex;
                justify-content: center;
                align-items: center;
            });

            img {
                display: block;
                margin: 0 auto;
                max-width: 168px;
                max-height: 68px;

                .media-max(@screen-xxs, {
                    max-width: 100%;
                });

                .media-min(@screen-l, {
                    max-width: 80%;
                    max-height: 92px;
                });
            }
        }
    }

    .notice {

    }
}